.Video-Container {
	width: 100%;
	height: 100%;
	border: none;
}

video {
	border-radius: 5px;
	position: relative;
	border: none;
	width: 100%;
}

#youtube-audio {
	position: absolute;
	background: #000;
	width: 0;
	height: 0;
	cursor: none;
	pointer-events: none;
}

#Click-Krimscanner {
	position: absolute;
	display: inline-block;
	width: 85%;
	height: 100%;
	padding: 5px 10px;
	right: 0;
	left: 0;
	z-index: 10;
	background-color: #000000c7;
	margin: 0% auto;
	border-radius: 10px;
}

#Click-Krimscanner p {
	font-size: 5vw;
	text-align: center;
	margin: 25% auto;
	color: rgb(184, 19, 19);
}

#Click-Krimscanner p:hover {
	color: rgb(6, 6, 179);
}
