/*Genereal Styling*/

.Skills-Wrapper {
  height: 35.8vw;
}

.Darken-Skills {
  height: 35.81vw;
  width: 23.84702194357367vw;
  border-radius: 3.01585735963581185vw;
  background-color: black;
  position: absolute;
  opacity: 0.95;
  z-index: 10;
}

.Darken-Skills:hover::after {
  content: "Click";
  color: white;
  font-family: Montserrat;
  font-size: 2.8vw;
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0.5;
  transform: translate(-50%, -50%);
}

.Lighten-Skills {
  visibility: hidden;
}
.Skills-Container {
  height: 100%;
  width: 23.84702194357367vw;
  border-radius: 3.1585735963581185vw;
  background-color: rgba(255, 255, 255, 1);
  max-height: inherit;
}

.Skills-Title {
  text-align: center;
  font-size: 2.503125vw;
  color: white;
}

.Skills-Image {
  box-sizing: border-box;
  display: block;
  max-width: 50%;
  height: auto;
  min-height: 40%;
  margin: 0px auto;
}

.Skills-Desc {
  text-align: center;
  color: #fff;
  margin: 1.9vw 2vw 2.5vw 2vw;
  letter-spacing: 0.7px;
  font-size: 0.92625vw;
  max-height: inherit;
}

.Skills-Progress-Container {
  width: 83.3%;
  font-size: 0.92625vw;
  max-height: inherit;
}

.Skills-Progress {
  margin: 0 10%;
  width: 100%;
  font-size: 0.92625vw;
  max-height: inherit;
}

.Skills-Progress-Level {
  display: flex;
  justify-content: space-between;
  margin: -0.5vw 1.8808777429467085vw;
}

.Skills-Progress-Level p {
  color: white;
  font-size: 1.003vw;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/*Not working for some reason*/
[class="Lighten-Skills"] {
  box-shadow: 0px 30px 10px rgba(0, 0, 0, 0.75);
}

#Click-Helper {
  width: max-content;
  font-size: 1.5vw !important;
  color: #fafafa !important;
}
/*HTML*/

.Skills-Container {
  background-color: #f1652a;
}

.Skills-Container:nth-child(1) .Moving-Level {
  fill: #9e2c18;
}

/*CSS*/
.Skills-Container:nth-child(2) {
  background-color: #1c88c8;
}

.Skills-Container:nth-child(2) .Moving-Level {
  fill: #4e548c;
}

/*JavaScript*/

.Skills-Container:nth-child(3) {
  background-color: #fead34;
}
.Skills-Container:nth-child(3) .Moving-Level {
  fill: #b57700;
}

/*Vue*/

#Vue {
  background-color: #fff;
  margin-left: -41.6vw;
}

#Vue h1,
#Vue em,
#Vue p {
  color: #41b883;
}

#Vue #Rectangle {
  fill: #34495e;
  stroke: none;
}
#Vuebar {
  fill: #3fc484;
}

#Vue-Gradient {
  width: 25.125vw;
  height: 36.9318002628122vw;
  background: rgb(60, 220, 149);
  background: linear-gradient(
    180deg,
    rgba(60, 220, 149, 1) 0%,
    rgba(59, 242, 160, 1) 20%,
    rgba(70, 239, 164, 0.9279644963454132) 50%,
    rgba(73, 230, 161, 1) 80%,
    rgba(80, 248, 173, 1) 100%
  );
  filter: blur(4px);
  z-index: -1;
  border-radius: 3.15vw;
  margin-top: -0.5314vw;
}

/*React*/

#React-Gradient {
  width: 25.125vw;
  height: 36.9318002628122vw;
  background: rgb(97, 219, 251);
  background: linear-gradient(
    180deg,
    rgba(97, 219, 251, 1) 0%,
    rgba(68, 164, 189, 1) 20%,
    rgba(97, 219, 251, 1) 50%,
    rgba(68, 164, 189, 1) 80%,
    rgba(97, 219, 251, 1) 100%
  );
  filter: blur(4px);
  z-index: -1;
  border-radius: 3.15vw;
  margin-top: -0.5314vw;
}

#React {
  background-color: #111725;
  margin-right: -41.5vw;
}
#Reactbar {
  fill: #fff;
}

#React #Rectangle {
  fill: rgb(34, 186, 251);
}
/*React Native*/

#React-Native-Gradient {
  width: 25.125vw;
  height: 36.9318002628122vw;
  background: rgb(97, 219, 251);
  background: linear-gradient(
    180deg,
    rgb(80, 217, 251) 0%,
    rgb(52, 161, 189) 20%,
    rgb(68, 215, 251) 50%,
    rgb(55, 162, 189) 80%,
    rgb(77, 219, 255) 100%
  );
  filter: blur(4px);
  z-index: -1;
  border-radius: 3.15vw;
  margin-top: -0.5314vw;
}

[id="React Native"] {
  background-color: #fff;
  margin-right: -62.2vw;
}

[id="React Native"] h1,
[id="React Native"] em,
[id="React Native"] p {
  color: #60dbfc;
  word-wrap: break-word;
}

#ReactNativebar {
  fill: rgb(227, 81, 210);
}

[id="React Native"] #Rectangle {
  fill: #4cd3f8;
}

/*Version Control*/

[id="Version Control"] img {
  height: 10vw;
  width: 10vw;
}

[id="Version Control"] {
  background-color: #fff;
}

[id="Version Control"] h1,
[id="Version Control"] em,
[id="Version Control"] p {
  color: #3d2d00;
}

[id="Version Control"] #Rectangle {
  fill: #f34f29;
}

#VersionControlbar {
  fill: #9e2c18;
  stroke: none;
}

/* TS */
#TypeScript {
  background-color: #007acc;
}

#TypeScript #Rectangle {
  fill: #ffffff;
}
