@import url("https://fonts.googleapis.com/css2?family=Mr+Dafoe&display=swap");

body {
  overflow-y: visible;
}

/*Contact Popup*/

.Contact-Popup {
  position: sticky;
  top: 87vh;
  margin-left: 0.5vw;
  z-index: 10;
  width: 4%;
  opacity: 1;
}

.Contact-Popup a,
.Contact-Popup li {
  text-decoration: none;
  list-style: none;
}

.Contact-Popup:hover:after {
  font-size: 1vw;
  top: 33%;
  margin-left: 5px;
  width: max-content;
  position: absolute;
  color: rgb(255, 255, 255);
  content: "Contact me!";
}

.Contact-Popup a,
.Contact-Popup li {
  text-decoration: none;
  list-style: none;
}

/*Mobileview*/

@media (max-width: 1200px) {
  .desktop,
  .desktop * {
    display: none;
  }
  .mobileView {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    height: auto;
    z-index: 100;
    font-family: montserat;
    color: #fff;
    background-image: url(../assets/IMG_1629.JPG);
    background-size: cover;
    padding-bottom: 150px;
  }

  .mobileView div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .mobileView p {
    text-align: center;
    font-size: clamp(12px, 20px, 2.7vw);
    margin: 0px;
  }

  .mobileView h1 {
    font-size: clamp(22px, 4.5vw, 5vw);
    margin-top: 9.6vw;
  }

  .mobileView .Form-Container {
    margin-top: 100px;
  }
}

/*Desktop*/

/*Mobile view off*/
@media (min-width: 1200px) {
  .mobileView {
    display: none;
  }
}

/* Index Table
1. Svg styling
2. Skills Cards
3. Krimscanner
4  Donify
5. Contact Form
6. Progressbar
*/

/*Svg styling*/

.svg-container {
  width: 100vw;
}

.Smack-Container {
  height: auto;
}

.svg-dialogue-container {
  width: 100vw;
}

#fullname {
  display: block;
  margin: 8vw auto 30vw auto;
  width: 100%;
  visibility: hidden;
}

#rocketman {
  position: absolute;
  visibility: hidden;
  margin-top: -20.6vw;
}

#Looking {
  position: absolute;
  visibility: hidden;
  margin-top: -5.5vw;
  top: 12.625vw;
  left: 19vw;
  z-index: 2;
}

#My-Name-Is {
  position: absolute;
  visibility: hidden;
  margin-top: -6.25vw;
  top: 11.625vw;
  left: 19vw;
  z-index: 2;
}

.Whops-Container {
  position: absolute;
  visibility: hidden;
  margin-top: -2.5vw;
  top: 15.46vw;
  right: -58vw;
  z-index: 2;
  width: 85vw;
}

.Ehm-Container {
  position: absolute;
  visibility: hidden;
  margin-top: -2.5vw;
  top: 15.46vw;
  right: -58vw;
  z-index: 2;
  width: 85vw;
}

/*Skills Cards*/

.Basic-Skills-Section {
  margin-bottom: 200px;
}

.Big-Skills-Title {
  text-align: center;
  color: white;
  font-size: 6vw;
  margin: 0px 0px 70px 0px;
}

.Big-Frameworks-Title {
  text-align: center;
  color: white;
  font-size: 6vw;
  margin-bottom: 100px;
}

.Big-Teamwork-Title {
  text-align: center;
  color: white;
  font-size: 6vw;
  margin: 200px 0px 70px 0px;
}

.Big-Projects-Title {
  text-align: center;
  color: white;
  font-size: 6vw;
  margin: 100px 0px 70px 0px;
}

.Projects-Paragraph {
  margin: 7vw 5vw;
  text-align: center;
  font-size: 3.5vw;
  color: white;
}

#React-Native-Container {
  margin-top: 7vw;
}

.Skills-Wrapper {
  display: flex;
  justify-content: space-evenly;
  height: 64.78318002628122vh;
  visibility: hidden;
}

.Skills-Wrapper:nth-child(1) {
  margin-bottom: 200px;
}

/*Git Wrapper*/
h3 + .Skills-Wrapper {
  margin-bottom: 8vw;
}

.Project-Headers {
  text-align: center;
  color: white;
  font-size: 5vw;
  margin: 10vw 0px 4vw 0px;
}

#Short-Preview {
  margin: 13vw 0px 5vw 0px;
}

/*Krimscanner*/

.TV-Container {
  margin: 0 auto;
  width: 100%;
}

.Krimscanner-Section {
  background-color: rgb(2, 2, 2);
  padding: 100px 0px 200px 0px;
}

.Image-Container {
  margin: 150px auto;
  width: 100vw;
  text-align: center;
}

.Image-Container img {
  max-width: 60%;
  min-height: 100%;
  border-radius: 23px;
}

.Krimscanner-Section button {
  display: flex;
  position: relative;
  margin: -8vw auto 2vw auto;
  padding: 15px;
  width: 20vw;
  background-color: #aa3f97;
  border-radius: 36px;
  outline: none;
  border: none;
  font-size: 2vw;
  font-family: Montserat;
  color: white;
}

.Krimscanner-Section button a {
  margin: 0 auto;
  text-decoration: none;
  color: white;
  border: none;
}

.Krimscanner-Section button:hover {
  background-color: #8f2f7d;
}

.Krimscanner-Feautres {
  display: flex;
  text-align: center;
  width: 100%;
}

.Krimscanner-Stats,
.Krimscanner-Search {
  width: 100%;
  display: block;
  margin-right: 6vw;
}

.Krimscanner-Feautres img {
  max-width: 65%;
  min-height: 50%;
  border-radius: 10px;
}

.Krimscanner-Feautres p {
  text-align: center;
  font-size: 2vw;
  color: white;
  margin-top: 25px;
}

.Krimscanner-Small-Title {
  font-size: 4vw !important;
}

.Krimscanner-Video-Container {
  width: 85%;
  margin: 0 auto;
}

#Banner-Krimscanner {
  margin: 7.5% auto -5% auto;
  height: 6vw;
  background-image: url(https://i.ibb.co/Vtcm76F/Crime-Scene-Do-Not-Cross-Tape-PNG-Clip-Art-Image.png);
  background-size: contain;
  background-repeat: repeat;
}

#copyright {
  color: white;
  position: relative;
  left: 76vw;
  margin-top: 25px;
  font-size: 1vw;
}

/*Donify*/

#Donify-Button {
  visibility: hidden;
  margin: 0 auto;
  text-decoration: none;
  color: white;
  border: none;
}

/*Slider*/

#panels-container {
  width: 500%;
}

#panels #panels-container {
  display: flex;
  flex-wrap: nowrap;
  padding: 0;
  overflow: hidden;
  background-color: white;
}

#panels #panels-container .panel {
  width: 100vw;
}

#panel-2 {
  display: flex;
  background-color: rgb(0, 195, 255);
  justify-content: center;
}

#panel-2 h2 {
  color: white;
  text-align: center;
  font-size: 6vw;
  margin: 25% auto;
}

#panel-2 h2 span {
  color: #9647f7;
}

/*Donify images*/

#panel-3 {
  background-color: #9647f7;
}

#panel-3 p {
  color: white;
  text-align: center;
  font-size: 3.3vw;
  margin: 2.9% 0% 6% 0%;
}

.donify-Image-Container {
  width: 100%;
  height: 26.5vw;
  display: flex;
  justify-content: space-evenly;
}

.donify-Image-Container img {
  width: 21%;
  max-width: 50%;
  border-radius: 12px;
  margin-bottom: 10px;
}

/*Donify Skills and Text*/

.donify-Skills-Image-Container {
  width: 70%;
  height: auto;
  display: flex;
  justify-content: space-evenly;
  margin: 5% auto;
}

.donify-Skills-Image-Container img {
  margin-top: 30px;
  width: 25%;
  max-width: 50%;
  border-radius: 23.48px;
  box-shadow: 29px 34px 1px rgba(0, 0, 0, 0.25);
}

#panel-4 {
  /*height: 100vw;*/
  background-color: rgb(252, 250, 250);
}

#panel-4 p {
  color: black;
  text-align: center;
  font-size: 2.2vw;
  margin: 7.5% 4% -1% 4%;
}

#credits {
  font-size: 1vw !important;
  margin-top: 70px !important;
}

#panels #panels-container .panel img {
  max-width: 100%;
  height: auto;
  display: block;
}

/*rest*/
#panels button {
  position: absolute;
  background-color: unset;
  color: whitesmoke;
  text-decoration: underline;
  top: 15vw;
  font-size: 9vw;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: 1em;
}

#panels button:hover {
  color: #ffd900;
}

#Donify {
  width: 100vw;
  height: auto;
}

.Donify-Desc {
  position: relative;
  left: 10%;
  margin-top: 7rem;
  text-align: center;
  font-size: 2.2em;
  width: 80%;
  color: white;
}

/*Progrssbar*/
progress {
  width: 100%;
  height: 12px;
  position: sticky;
  z-index: 1000;
  top: 0;
  left: 0;
  appearance: none;
  border: none;
  background: transparent;
}

progress::-webkit-progress-bar {
  background: transparent;
}

progress::-webkit-progress-value {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background: linear-gradient(to right, #7137b8, #8d53d4, #9d56f5);
  background-attachment: fixed;
}

progress::-moz-progress-bar {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background: linear-gradient(to left, #9d56f5, #6e35b4, #7137b8);
  background-attachment: fixed;
}
