@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,700;1,700&display=swap");

body {
  margin: 0;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  height: auto;
  background-size: cover;
  background-size: contain;
  /* Fallback background color for browers who supprt bounce on scroll */
  background: #b72e82 url(./assets/IMG_1629.JPG);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
textarea {
  font-family: "Montserrat", sans-serif;
  padding: 0;
}
