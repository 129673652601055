* {
  font-family: Montserrat;
}

.Big-Guestbook-Title {
  text-align: center;
  color: white;
  font-size: 6vw;
  margin: 13vw 0 2vw 0;
}

.Guest-Card-Container-Blue,
.Guest-Card-Container-Orange,
.Guest-Card-Container-Space {
  margin: 0 auto;
  border-radius: 2vw;
  /*height: 30vw;*/
  height: auto;
  padding-bottom: 0.3vw;
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: -1;
  margin-bottom: 10vw;
  /*Parent fontsize*/
  font-size: 3vw;
  white-space: normal;
  overflow: hidden;
  color: #fff;
  flex-wrap: wrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*Theme Color*/
.Guest-Card-Container-Orange {
  background: rgb(228, 96, 78);
  background: linear-gradient(
    125deg,
    rgba(228, 96, 78, 1) 0%,
    rgba(238, 99, 99, 1) 32%,
    rgba(228, 84, 144, 0.9699813030681023) 100%
  );
}
.Guest-Card-Container-Blue {
  background: rgb(228, 96, 78);
  background: linear-gradient(
    125deg,
    rgb(36, 24, 204) 0%,
    rgb(94, 42, 190) 20%,
    rgba(228, 84, 144, 0.9699813030681023) 100%
  );
}

.Guest-Card-Container-Space {
  background: rgb(228, 96, 78);
  background: linear-gradient(
    125deg,
    rgb(17, 245, 245) 0%,
    rgb(255, 91, 239) 100%,
    rgba(255, 255, 255, 0.9) 100%
  );
}

.Guest-Name {
  padding-top: 3.8vw;
  padding-bottom: 0.4vw;
  text-align: center;
  font-size: clamp(1vw, 1.45em, 4vw);
  max-width: 66%;
  overflow-wrap: break-word;
}

.Guest-Message {
  overflow-wrap: break-word;
  position: relative;
  font-size: 0.7em;
  width: max-content;
  padding-top: 6vw;
  padding-bottom: 6.2vw;
  margin: 0;
  background: transparent;
  border: none;
  width: 80%;
  resize: none;
  outline: none;
  color: #fff;
  text-align: center;
  pointer-events: none;
}

.Guest-Message-Counter {
  font-size: clamp(1vw, 1.4vw, 2vw);
  text-align: center;
  color: rgb(255, 255, 255);
}

.Guest-Vocation {
  overflow-wrap: break-word;
  font-size: 0.7em;
  font-style: italic;
  flex-direction: column;
  margin: 0;
}

.Guest-Image {
  margin-top: -2.5vw;
  position: absolute;
  left: 68.5vw;
  height: 11.5vw;
  width: 11.5%;
  border-radius: 360%;
  z-index: 10;
}

.Guest-Image-Circle-Orange,
.Guest-Image-Circle-Blue,
.Guest-Image-Circle-Space {
  margin-top: -2.79vw;
  position: absolute;
  left: 68.2vw;
  height: 12.09vw;
  width: 12%;
  border-radius: 360%;
}

.Guest-Image-Circle-Orange {
  background: rgb(216, 91, 74);
  background: linear-gradient(
    460deg,
    rgba(216, 91, 74, 1) 0%,
    rgba(220, 91, 101, 1) 33%,
    rgba(203, 82, 108, 1) 66%,
    rgba(182, 70, 114, 1) 100%
  );
  /*border: 1px solid white;*/
}

.Guest-Image-Circle-Blue {
  background: rgb(228, 96, 78);
  background: linear-gradient(
    470deg,
    rgba(63, 24, 204, 0.452) 0%,
    rgb(94, 42, 190) 20%,
    rgba(228, 84, 144, 0.9699813030681023) 100%
  );
}

.Guest-Image-Circle-Space {
  background: rgb(228, 96, 78);
  background: linear-gradient(
    460deg,
    rgb(17, 245, 245) 0%,
    rgb(255, 91, 239) 100%,
    rgba(255, 255, 255, 0.9) 100%
  );
}

.Guest-Date {
  position: relative;
  left: 20vw;
  font-size: 1.6vw;
  padding-bottom: 1.05vw;
  margin-top: 1.5vw;
}

/*Form*/

.Form-Header {
  color: white;
  font-size: 4.3vw;
  text-align: center;
  margin: 20vw 0 15vw 0;
}
.Form-Header span {
  padding: 10px;
  border-radius: 20em;
}

.Form-Header span:nth-child(1) {
  background: rgb(216, 91, 74);
  background: linear-gradient(
    460deg,
    rgba(216, 91, 74, 1) 0%,
    rgba(220, 91, 101, 1) 33%,
    rgba(203, 82, 108, 1) 66%,
    rgba(182, 70, 114, 1) 100%
  );
  margin-right: 3.5vw;
}

.Form-Header span:nth-child(2) {
  background: rgb(228, 96, 78);
  background: linear-gradient(
    470deg,
    rgba(63, 24, 204, 0.452) 0%,
    rgb(94, 42, 190) 20%,
    rgba(228, 84, 144, 0.9699813030681023) 100%
  );
  margin-right: 3.5vw;
}

.Form-Header span:nth-child(3) {
  background: rgb(228, 96, 78);
  background: linear-gradient(
    460deg,
    rgb(17, 245, 245) 0%,
    rgb(255, 91, 239) 100%,
    rgba(255, 255, 255, 0.9) 100%
  );
}
.Form-Header + p {
  color: white;
  text-align: center;
}

.Guest-Form {
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10vw;
  margin-top: 3vw;
}

.Form-Name {
  width: 35%;
  margin-bottom: 1vw;
}
.Form-Name-Input {
  width: 100%;
  padding: 1vw;
  border-radius: 0.8vw;
  border: 2.5px solid rgb(0, 37, 160);
  font-size: 1em;
  outline: rgb(255, 2, 2);
}

.Form-Vocation {
  width: 35%;
  margin-bottom: 1vw;
}

.Form-Vocation-Input {
  width: 100%;
  padding: 1vw;
  border-radius: 0.8vw;
  border: 2.5px solid rgb(199, 107, 3);
  font-size: 1em;
  outline: rgb(255, 2, 2);
}

.Form-Message {
  width: 35%;
}

.Form-Message-Textarea {
  resize: none;
  border: 4px solid rgb(90, 4, 112);
  border-radius: 1.1vw;
  padding: 1vw;
  outline: rgb(255, 2, 2);
  width: 100%;
}

.Form-File-Container {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
  gap: 10px;
  font-size: clamp(1vw, 2.3vw, 3vw);
  margin: 4vw 0;
}

.Form-File-Container span {
  color: #fff;
}

.Guestbook-Label-File-Selector {
  width: auto;
}

.Guestbook-File-Selector {
  margin-top: 15px;
  padding: 1vw;
  border-radius: 1vw;
  background-color: rgb(255, 123, 0);
  color: white;
  border: 1px solid silver;
  width: 20%;
}

.Guestbook-File-Selector:hover {
  background-color: rgb(255, 102, 0);
}

/*Cross icon*/
.fa-times {
  color: rgb(255, 0, 0);
  font-size: 4.5vw;
  margin-left: 5px;
  margin-top: 1px;
}

.fa-times:hover {
  color: rgb(223, 0, 0);
}

[type="file"] {
  display: none;
}

/*Trash Can*/

.fa-trash {
  color: rgb(163, 30, 119);
  position: absolute;
  left: 25vw;
  margin-top: -4vw;
}

.fa-trash:hover:after {
  content: "Delete Guestbook";
  font-size: 1vw;
  width: 1vw;
  margin-top: 1.7vw;
  margin-left: 0.7vw;
  color: #fff;
  font-family: Montserrat;
}

.Guestbook-Theme-Container {
  margin-top: 3vw;
  display: flex;
  justify-content: space-between;
  gap: 1.5vw;
  padding-bottom: 1vw;
}

.Guestbook-Theme-Orange {
  background: rgb(228, 96, 78);
  background: linear-gradient(
    125deg,
    rgba(228, 96, 78, 1) 0%,
    rgba(238, 99, 99, 1) 32%,
    rgba(228, 84, 144, 0.9699813030681023) 100%
  );
  height: 8vw;
  width: 8vw;
  border-radius: 360%;
  border: 1px solid white;
}

.Guestbook-Theme-Blue {
  background: rgb(228, 96, 78);
  background: linear-gradient(
    125deg,
    rgb(36, 24, 204) 0%,
    rgb(42, 82, 190) 32%,
    rgba(228, 84, 144, 0.9699813030681023) 100%
  );
  height: 8vw;
  width: 8vw;
  border-radius: 360%;
  border: 1px solid white;
}

.Guestbook-Theme-Space {
  background: rgb(228, 96, 78);
  background: linear-gradient(
    125deg,
    rgb(17, 245, 245) 0%,
    rgb(255, 91, 239) 100%,
    rgba(255, 255, 255, 0.9) 100%
  );
  height: 8vw;
  width: 8vw;
  border-radius: 360%;
  border: 1px solid white;
}

.Guestbook-Theme-Space:hover,
.Guestbook-Theme-Blue:hover,
.Guestbook-Theme-Orange:hover {
  height: 10vw;
  width: 10vw;
  margin-top: -0.5vw;
}

.Selected-Theme {
  border: 3px solid rgb(255, 2, 2);
  height: 8vw;
  width: 8vw;
  margin-top: -0.5vw;
}

.Theme-Circle-Text {
  color: rgb(255, 255, 255);
  margin: 1.7vw 0 3vw 0;
  position: relative;
}

.Guestbook-Button-Submit {
  background-color: rgb(255, 123, 0);
  border: 1px solid silver;
  margin-top: 3.5vw;
  padding: 1.1vw;
  font-size: 1.6vw;
  border-radius: 3vw;
  width: 33%;
  color: white;
}

button[disabled] {
  color: black;
  background-color: white;
  border: 2px solid black;
}

button[disabled]:hover::after {
  font-size: 1.45vw;
  position: absolute;
  margin-top: 4.5vw;
  left: 0;
  right: 0;
  color: white;
  content: "Fill in all inputs and select a theme";
}

.LikesSvgContainer {
  position: relative;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 10vw;
  position: sticky;
  left: 87vw;
  top: 8vw;
  gap: 1vw;
}

.SVG-Responsive-Container {
  max-width: 50%;
}

.Likes-And-SVG-Responsive-Container {
  display: flex;
  margin: 0 auto;
  position: relative;
  align-items: center;
  width: 80%;
  margin-bottom: -85%;
}

#Likes {
  /*position: relative*/
  flex-grow: 2;
  width: auto;
  color: white;
  font-size: calc(0.5vw + 2ch);
  /*font-size: 2ch;*/
  top: -6.4vw;
  left: 5vw;
  margin-bottom: 3px;
}

#LikeButton {
  border: 3px solid #7159d2;
  background-color: transparent;
  color: white;
  font-size: 0.85vw;
  font-family: Montserrat;
  width: 10vw;
  stroke-width: 10px;
  padding: 0.81vw 1vw;
  border-radius: 22%/70%;
  background: rgb(32, 23, 108);
  background: linear-gradient(
    180deg,
    rgba(32, 23, 108, 1) 0%,
    rgba(113, 89, 210, 0) 100%
  );
}

#LikeButton:hover {
  background-color: #7159d2c7;
}
