@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,700;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mr+Dafoe&display=swap);
body {
  margin: 0;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  height: auto;
  background-size: cover;
  background-size: contain;
  /* Fallback background color for browers who supprt bounce on scroll */
  background: #b72e82 url(/static/media/IMG_1629.dc95a145.JPG);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
textarea {
  font-family: "Montserrat", sans-serif;
  padding: 0;
}

/*Contact Form*/
.white {
  background-color: rgb(0, 0, 0);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-top: -5px;
  height: 100%;
  width: 100%;
  z-index: 100;
  position: absolute;
  top: 0;
}

.white p:nth-child(1) {
  position: absolute;
  left: 2.5%;
  top: 22%;
  width: 21%;
  color: #fff;
  text-align: center;
  font-family: "Mr Dafoe";
  font-size: 3.5vw;
}

.Link-Home {
  background-color: "red";
  width: 10vw;
  height: 10vw;
  position: absolute;
  z-index: 102;
}

#Exit-Contact {
  color: #fff;
  z-index: 101;
  font-size: 5vw;
  position: absolute;
  z-index: 101;
  left: 94vw;
  top: 2vw;
}

#Exit-Contact:hover {
  color: red;
}

.Link-Home {
  z-index: 102;
  position: absolute;
}

.More-Content {
  margin-top: 20vw;
  display: -webkit-flex;
  display: flex;
  justify-self: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.white h1 {
  margin: 0px;
  text-align: center;
  font-size: 5vw;
}

.More-Content p {
  font-size: 1.5vw;
}

.Form-Container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: #000;
  width: 45%;
  height: 75%;
  margin: 0 auto;
  /*border: 1px solid rgb(2, 2, 2);*/
  border-radius: 1.5vw;
  padding: 1% 10px 5%;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 101;
  /*padding: 10vw 0;*/
}

.Form-Container form {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 55%;
  grid-gap: clamp(5px, 1.5vw, 2vw);
  gap: clamp(5px, 1.5vw, 2vw);
}

.Form-Container p {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-size: 4vw;
  margin: 4vw 0 0.5vw 0;
  color: #fff;
}

.Form-Container label {
  width: 100%;
  padding: 0px;
}

.Form-Group {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

.Form-Group textarea {
  width: 90%;
  /*margin-left: -0.6vw;*/
  padding: 10px 5%;
  background-color: white;
  font-size: 1.6vw;
  color: black;
  border: none;
  outline: none;
  border-radius: 8px;
  resize: none;
  overflow: hidden;
  display: block;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.Form-Container input {
  width: 90%;
  display: block;
  /*margin: 0 auto;*/
  padding: 0.5em 5%;
  /*margin-left: -0.9vw;*/
  border: none;
  border-radius: 8px;
  background-color: white;
  font-size: 1.6vw;
  color: black;
  outline: none;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.Form-Container button:hover {
  text-decoration: underline;
}

.Form-Container button {
  width: 100%;
  /*padding: 10px 10px;*/
  border: none;
  background-color: white;
  color: black;
  border-radius: 115px;
  font-size: 2.4vw !important ;
  cursor: pointer;
}

.Form-Group textarea::-webkit-input-placeholder, .Form-Container button::-webkit-input-placeholder, .Form-Container input::-webkit-input-placeholder {
  font-family: "Mr Dafoe", cursive;
  font-size: 2vw;
}

.Form-Group textarea:-ms-input-placeholder, .Form-Container button:-ms-input-placeholder, .Form-Container input:-ms-input-placeholder {
  font-family: "Mr Dafoe", cursive;
  font-size: 2vw;
}

.Form-Group textarea::placeholder,
.Form-Container button::placeholder,
.Form-Container input::placeholder,
.Form-Container button {
  font-family: "Mr Dafoe", cursive;
  font-size: 2vw;
}

body {
  overflow-y: visible;
}

/*Contact Popup*/

.Contact-Popup {
  position: -webkit-sticky;
  position: sticky;
  top: 87vh;
  margin-left: 0.5vw;
  z-index: 10;
  width: 4%;
  opacity: 1;
}

.Contact-Popup a,
.Contact-Popup li {
  text-decoration: none;
  list-style: none;
}

.Contact-Popup:hover:after {
  font-size: 1vw;
  top: 33%;
  margin-left: 5px;
  width: -webkit-max-content;
  width: max-content;
  position: absolute;
  color: rgb(255, 255, 255);
  content: "Contact me!";
}

.Contact-Popup a,
.Contact-Popup li {
  text-decoration: none;
  list-style: none;
}

/*Mobileview*/

@media (max-width: 1200px) {
  .desktop,
  .desktop * {
    display: none;
  }
  .mobileView {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100vw;
    height: auto;
    z-index: 100;
    font-family: montserat;
    color: #fff;
    background-image: url(/static/media/IMG_1629.dc95a145.JPG);
    background-size: cover;
    padding-bottom: 150px;
  }

  .mobileView div {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .mobileView p {
    text-align: center;
    font-size: clamp(12px, 20px, 2.7vw);
    margin: 0px;
  }

  .mobileView h1 {
    font-size: clamp(22px, 4.5vw, 5vw);
    margin-top: 9.6vw;
  }

  .mobileView .Form-Container {
    margin-top: 100px;
  }
}

/*Desktop*/

/*Mobile view off*/
@media (min-width: 1200px) {
  .mobileView {
    display: none;
  }
}

/* Index Table
1. Svg styling
2. Skills Cards
3. Krimscanner
4  Donify
5. Contact Form
6. Progressbar
*/

/*Svg styling*/

.svg-container {
  width: 100vw;
}

.Smack-Container {
  height: auto;
}

.svg-dialogue-container {
  width: 100vw;
}

#fullname {
  display: block;
  margin: 8vw auto 30vw auto;
  width: 100%;
  visibility: hidden;
}

#rocketman {
  position: absolute;
  visibility: hidden;
  margin-top: -20.6vw;
}

#Looking {
  position: absolute;
  visibility: hidden;
  margin-top: -5.5vw;
  top: 12.625vw;
  left: 19vw;
  z-index: 2;
}

#My-Name-Is {
  position: absolute;
  visibility: hidden;
  margin-top: -6.25vw;
  top: 11.625vw;
  left: 19vw;
  z-index: 2;
}

.Whops-Container {
  position: absolute;
  visibility: hidden;
  margin-top: -2.5vw;
  top: 15.46vw;
  right: -58vw;
  z-index: 2;
  width: 85vw;
}

.Ehm-Container {
  position: absolute;
  visibility: hidden;
  margin-top: -2.5vw;
  top: 15.46vw;
  right: -58vw;
  z-index: 2;
  width: 85vw;
}

/*Skills Cards*/

.Basic-Skills-Section {
  margin-bottom: 200px;
}

.Big-Skills-Title {
  text-align: center;
  color: white;
  font-size: 6vw;
  margin: 0px 0px 70px 0px;
}

.Big-Frameworks-Title {
  text-align: center;
  color: white;
  font-size: 6vw;
  margin-bottom: 100px;
}

.Big-Teamwork-Title {
  text-align: center;
  color: white;
  font-size: 6vw;
  margin: 200px 0px 70px 0px;
}

.Big-Projects-Title {
  text-align: center;
  color: white;
  font-size: 6vw;
  margin: 100px 0px 70px 0px;
}

.Projects-Paragraph {
  margin: 7vw 5vw;
  text-align: center;
  font-size: 3.5vw;
  color: white;
}

#React-Native-Container {
  margin-top: 7vw;
}

.Skills-Wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  height: 64.78318002628122vh;
  visibility: hidden;
}

.Skills-Wrapper:nth-child(1) {
  margin-bottom: 200px;
}

/*Git Wrapper*/
h3 + .Skills-Wrapper {
  margin-bottom: 8vw;
}

.Project-Headers {
  text-align: center;
  color: white;
  font-size: 5vw;
  margin: 10vw 0px 4vw 0px;
}

#Short-Preview {
  margin: 13vw 0px 5vw 0px;
}

/*Krimscanner*/

.TV-Container {
  margin: 0 auto;
  width: 100%;
}

.Krimscanner-Section {
  background-color: rgb(2, 2, 2);
  padding: 100px 0px 200px 0px;
}

.Image-Container {
  margin: 150px auto;
  width: 100vw;
  text-align: center;
}

.Image-Container img {
  max-width: 60%;
  min-height: 100%;
  border-radius: 23px;
}

.Krimscanner-Section button {
  display: -webkit-flex;
  display: flex;
  position: relative;
  margin: -8vw auto 2vw auto;
  padding: 15px;
  width: 20vw;
  background-color: #aa3f97;
  border-radius: 36px;
  outline: none;
  border: none;
  font-size: 2vw;
  font-family: Montserat;
  color: white;
}

.Krimscanner-Section button a {
  margin: 0 auto;
  text-decoration: none;
  color: white;
  border: none;
}

.Krimscanner-Section button:hover {
  background-color: #8f2f7d;
}

.Krimscanner-Feautres {
  display: -webkit-flex;
  display: flex;
  text-align: center;
  width: 100%;
}

.Krimscanner-Stats,
.Krimscanner-Search {
  width: 100%;
  display: block;
  margin-right: 6vw;
}

.Krimscanner-Feautres img {
  max-width: 65%;
  min-height: 50%;
  border-radius: 10px;
}

.Krimscanner-Feautres p {
  text-align: center;
  font-size: 2vw;
  color: white;
  margin-top: 25px;
}

.Krimscanner-Small-Title {
  font-size: 4vw !important;
}

.Krimscanner-Video-Container {
  width: 85%;
  margin: 0 auto;
}

#Banner-Krimscanner {
  margin: 7.5% auto -5% auto;
  height: 6vw;
  background-image: url(https://i.ibb.co/Vtcm76F/Crime-Scene-Do-Not-Cross-Tape-PNG-Clip-Art-Image.png);
  background-size: contain;
  background-repeat: repeat;
}

#copyright {
  color: white;
  position: relative;
  left: 76vw;
  margin-top: 25px;
  font-size: 1vw;
}

/*Donify*/

#Donify-Button {
  visibility: hidden;
  margin: 0 auto;
  text-decoration: none;
  color: white;
  border: none;
}

/*Slider*/

#panels-container {
  width: 500%;
}

#panels #panels-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  padding: 0;
  overflow: hidden;
  background-color: white;
}

#panels #panels-container .panel {
  width: 100vw;
}

#panel-2 {
  display: -webkit-flex;
  display: flex;
  background-color: rgb(0, 195, 255);
  -webkit-justify-content: center;
          justify-content: center;
}

#panel-2 h2 {
  color: white;
  text-align: center;
  font-size: 6vw;
  margin: 25% auto;
}

#panel-2 h2 span {
  color: #9647f7;
}

/*Donify images*/

#panel-3 {
  background-color: #9647f7;
}

#panel-3 p {
  color: white;
  text-align: center;
  font-size: 3.3vw;
  margin: 2.9% 0% 6% 0%;
}

.donify-Image-Container {
  width: 100%;
  height: 26.5vw;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.donify-Image-Container img {
  width: 21%;
  max-width: 50%;
  border-radius: 12px;
  margin-bottom: 10px;
}

/*Donify Skills and Text*/

.donify-Skills-Image-Container {
  width: 70%;
  height: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  margin: 5% auto;
}

.donify-Skills-Image-Container img {
  margin-top: 30px;
  width: 25%;
  max-width: 50%;
  border-radius: 23.48px;
  box-shadow: 29px 34px 1px rgba(0, 0, 0, 0.25);
}

#panel-4 {
  /*height: 100vw;*/
  background-color: rgb(252, 250, 250);
}

#panel-4 p {
  color: black;
  text-align: center;
  font-size: 2.2vw;
  margin: 7.5% 4% -1% 4%;
}

#credits {
  font-size: 1vw !important;
  margin-top: 70px !important;
}

#panels #panels-container .panel img {
  max-width: 100%;
  height: auto;
  display: block;
}

/*rest*/
#panels button {
  position: absolute;
  background-color: unset;
  color: whitesmoke;
  text-decoration: underline;
  top: 15vw;
  font-size: 9vw;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: 1em;
}

#panels button:hover {
  color: #ffd900;
}

#Donify {
  width: 100vw;
  height: auto;
}

.Donify-Desc {
  position: relative;
  left: 10%;
  margin-top: 7rem;
  text-align: center;
  font-size: 2.2em;
  width: 80%;
  color: white;
}

/*Progrssbar*/
progress {
  width: 100%;
  height: 12px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1000;
  top: 0;
  left: 0;
  -webkit-appearance: none;
          appearance: none;
  border: none;
  background: transparent;
}

progress::-webkit-progress-bar {
  background: transparent;
}

progress::-webkit-progress-value {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background: linear-gradient(to right, #7137b8, #8d53d4, #9d56f5);
  background-attachment: fixed;
}

progress::-moz-progress-bar {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background: linear-gradient(to left, #9d56f5, #6e35b4, #7137b8);
  background-attachment: fixed;
}

/*Genereal Styling*/

.Skills-Wrapper {
  height: 35.8vw;
}

.Darken-Skills {
  height: 35.81vw;
  width: 23.84702194357367vw;
  border-radius: 3.01585735963581185vw;
  background-color: black;
  position: absolute;
  opacity: 0.95;
  z-index: 10;
}

.Darken-Skills:hover::after {
  content: "Click";
  color: white;
  font-family: Montserrat;
  font-size: 2.8vw;
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0.5;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.Lighten-Skills {
  visibility: hidden;
}
.Skills-Container {
  height: 100%;
  width: 23.84702194357367vw;
  border-radius: 3.1585735963581185vw;
  background-color: rgba(255, 255, 255, 1);
  max-height: inherit;
}

.Skills-Title {
  text-align: center;
  font-size: 2.503125vw;
  color: white;
}

.Skills-Image {
  box-sizing: border-box;
  display: block;
  max-width: 50%;
  height: auto;
  min-height: 40%;
  margin: 0px auto;
}

.Skills-Desc {
  text-align: center;
  color: #fff;
  margin: 1.9vw 2vw 2.5vw 2vw;
  letter-spacing: 0.7px;
  font-size: 0.92625vw;
  max-height: inherit;
}

.Skills-Progress-Container {
  width: 83.3%;
  font-size: 0.92625vw;
  max-height: inherit;
}

.Skills-Progress {
  margin: 0 10%;
  width: 100%;
  font-size: 0.92625vw;
  max-height: inherit;
}

.Skills-Progress-Level {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: -0.5vw 1.8808777429467085vw;
}

.Skills-Progress-Level p {
  color: white;
  font-size: 1.003vw;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/*Not working for some reason*/
[class="Lighten-Skills"] {
  box-shadow: 0px 30px 10px rgba(0, 0, 0, 0.75);
}

#Click-Helper {
  width: -webkit-max-content;
  width: max-content;
  font-size: 1.5vw !important;
  color: #fafafa !important;
}
/*HTML*/

.Skills-Container {
  background-color: #f1652a;
}

.Skills-Container:nth-child(1) .Moving-Level {
  fill: #9e2c18;
}

/*CSS*/
.Skills-Container:nth-child(2) {
  background-color: #1c88c8;
}

.Skills-Container:nth-child(2) .Moving-Level {
  fill: #4e548c;
}

/*JavaScript*/

.Skills-Container:nth-child(3) {
  background-color: #fead34;
}
.Skills-Container:nth-child(3) .Moving-Level {
  fill: #b57700;
}

/*Vue*/

#Vue {
  background-color: #fff;
  margin-left: -41.6vw;
}

#Vue h1,
#Vue em,
#Vue p {
  color: #41b883;
}

#Vue #Rectangle {
  fill: #34495e;
  stroke: none;
}
#Vuebar {
  fill: #3fc484;
}

#Vue-Gradient {
  width: 25.125vw;
  height: 36.9318002628122vw;
  background: rgb(60, 220, 149);
  background: linear-gradient(
    180deg,
    rgba(60, 220, 149, 1) 0%,
    rgba(59, 242, 160, 1) 20%,
    rgba(70, 239, 164, 0.9279644963454132) 50%,
    rgba(73, 230, 161, 1) 80%,
    rgba(80, 248, 173, 1) 100%
  );
  -webkit-filter: blur(4px);
          filter: blur(4px);
  z-index: -1;
  border-radius: 3.15vw;
  margin-top: -0.5314vw;
}

/*React*/

#React-Gradient {
  width: 25.125vw;
  height: 36.9318002628122vw;
  background: rgb(97, 219, 251);
  background: linear-gradient(
    180deg,
    rgba(97, 219, 251, 1) 0%,
    rgba(68, 164, 189, 1) 20%,
    rgba(97, 219, 251, 1) 50%,
    rgba(68, 164, 189, 1) 80%,
    rgba(97, 219, 251, 1) 100%
  );
  -webkit-filter: blur(4px);
          filter: blur(4px);
  z-index: -1;
  border-radius: 3.15vw;
  margin-top: -0.5314vw;
}

#React {
  background-color: #111725;
  margin-right: -41.5vw;
}
#Reactbar {
  fill: #fff;
}

#React #Rectangle {
  fill: rgb(34, 186, 251);
}
/*React Native*/

#React-Native-Gradient {
  width: 25.125vw;
  height: 36.9318002628122vw;
  background: rgb(97, 219, 251);
  background: linear-gradient(
    180deg,
    rgb(80, 217, 251) 0%,
    rgb(52, 161, 189) 20%,
    rgb(68, 215, 251) 50%,
    rgb(55, 162, 189) 80%,
    rgb(77, 219, 255) 100%
  );
  -webkit-filter: blur(4px);
          filter: blur(4px);
  z-index: -1;
  border-radius: 3.15vw;
  margin-top: -0.5314vw;
}

[id="React Native"] {
  background-color: #fff;
  margin-right: -62.2vw;
}

[id="React Native"] h1,
[id="React Native"] em,
[id="React Native"] p {
  color: #60dbfc;
  word-wrap: break-word;
}

#ReactNativebar {
  fill: rgb(227, 81, 210);
}

[id="React Native"] #Rectangle {
  fill: #4cd3f8;
}

/*Version Control*/

[id="Version Control"] img {
  height: 10vw;
  width: 10vw;
}

[id="Version Control"] {
  background-color: #fff;
}

[id="Version Control"] h1,
[id="Version Control"] em,
[id="Version Control"] p {
  color: #3d2d00;
}

[id="Version Control"] #Rectangle {
  fill: #f34f29;
}

#VersionControlbar {
  fill: #9e2c18;
  stroke: none;
}

/* TS */
#TypeScript {
  background-color: #007acc;
}

#TypeScript #Rectangle {
  fill: #ffffff;
}

.Video-Container {
	width: 100%;
	height: 100%;
	border: none;
}

video {
	border-radius: 5px;
	position: relative;
	border: none;
	width: 100%;
}

#youtube-audio {
	position: absolute;
	background: #000;
	width: 0;
	height: 0;
	cursor: none;
	pointer-events: none;
}

#Click-Krimscanner {
	position: absolute;
	display: inline-block;
	width: 85%;
	height: 100%;
	padding: 5px 10px;
	right: 0;
	left: 0;
	z-index: 10;
	background-color: #000000c7;
	margin: 0% auto;
	border-radius: 10px;
}

#Click-Krimscanner p {
	font-size: 5vw;
	text-align: center;
	margin: 25% auto;
	color: rgb(184, 19, 19);
}

#Click-Krimscanner p:hover {
	color: rgb(6, 6, 179);
}

* {
  font-family: Montserrat;
}

.Big-Guestbook-Title {
  text-align: center;
  color: white;
  font-size: 6vw;
  margin: 13vw 0 2vw 0;
}

.Guest-Card-Container-Blue,
.Guest-Card-Container-Orange,
.Guest-Card-Container-Space {
  margin: 0 auto;
  border-radius: 2vw;
  /*height: 30vw;*/
  height: auto;
  padding-bottom: 0.3vw;
  width: 55%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  z-index: -1;
  margin-bottom: 10vw;
  /*Parent fontsize*/
  font-size: 3vw;
  white-space: normal;
  overflow: hidden;
  color: #fff;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*Theme Color*/
.Guest-Card-Container-Orange {
  background: rgb(228, 96, 78);
  background: linear-gradient(
    125deg,
    rgba(228, 96, 78, 1) 0%,
    rgba(238, 99, 99, 1) 32%,
    rgba(228, 84, 144, 0.9699813030681023) 100%
  );
}
.Guest-Card-Container-Blue {
  background: rgb(228, 96, 78);
  background: linear-gradient(
    125deg,
    rgb(36, 24, 204) 0%,
    rgb(94, 42, 190) 20%,
    rgba(228, 84, 144, 0.9699813030681023) 100%
  );
}

.Guest-Card-Container-Space {
  background: rgb(228, 96, 78);
  background: linear-gradient(
    125deg,
    rgb(17, 245, 245) 0%,
    rgb(255, 91, 239) 100%,
    rgba(255, 255, 255, 0.9) 100%
  );
}

.Guest-Name {
  padding-top: 3.8vw;
  padding-bottom: 0.4vw;
  text-align: center;
  font-size: clamp(1vw, 1.45em, 4vw);
  max-width: 66%;
  overflow-wrap: break-word;
}

.Guest-Message {
  overflow-wrap: break-word;
  position: relative;
  font-size: 0.7em;
  width: -webkit-max-content;
  width: max-content;
  padding-top: 6vw;
  padding-bottom: 6.2vw;
  margin: 0;
  background: transparent;
  border: none;
  width: 80%;
  resize: none;
  outline: none;
  color: #fff;
  text-align: center;
  pointer-events: none;
}

.Guest-Message-Counter {
  font-size: clamp(1vw, 1.4vw, 2vw);
  text-align: center;
  color: rgb(255, 255, 255);
}

.Guest-Vocation {
  overflow-wrap: break-word;
  font-size: 0.7em;
  font-style: italic;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 0;
}

.Guest-Image {
  margin-top: -2.5vw;
  position: absolute;
  left: 68.5vw;
  height: 11.5vw;
  width: 11.5%;
  border-radius: 360%;
  z-index: 10;
}

.Guest-Image-Circle-Orange,
.Guest-Image-Circle-Blue,
.Guest-Image-Circle-Space {
  margin-top: -2.79vw;
  position: absolute;
  left: 68.2vw;
  height: 12.09vw;
  width: 12%;
  border-radius: 360%;
}

.Guest-Image-Circle-Orange {
  background: rgb(216, 91, 74);
  background: linear-gradient(
    460deg,
    rgba(216, 91, 74, 1) 0%,
    rgba(220, 91, 101, 1) 33%,
    rgba(203, 82, 108, 1) 66%,
    rgba(182, 70, 114, 1) 100%
  );
  /*border: 1px solid white;*/
}

.Guest-Image-Circle-Blue {
  background: rgb(228, 96, 78);
  background: linear-gradient(
    470deg,
    rgba(63, 24, 204, 0.452) 0%,
    rgb(94, 42, 190) 20%,
    rgba(228, 84, 144, 0.9699813030681023) 100%
  );
}

.Guest-Image-Circle-Space {
  background: rgb(228, 96, 78);
  background: linear-gradient(
    460deg,
    rgb(17, 245, 245) 0%,
    rgb(255, 91, 239) 100%,
    rgba(255, 255, 255, 0.9) 100%
  );
}

.Guest-Date {
  position: relative;
  left: 20vw;
  font-size: 1.6vw;
  padding-bottom: 1.05vw;
  margin-top: 1.5vw;
}

/*Form*/

.Form-Header {
  color: white;
  font-size: 4.3vw;
  text-align: center;
  margin: 20vw 0 15vw 0;
}
.Form-Header span {
  padding: 10px;
  border-radius: 20em;
}

.Form-Header span:nth-child(1) {
  background: rgb(216, 91, 74);
  background: linear-gradient(
    460deg,
    rgba(216, 91, 74, 1) 0%,
    rgba(220, 91, 101, 1) 33%,
    rgba(203, 82, 108, 1) 66%,
    rgba(182, 70, 114, 1) 100%
  );
  margin-right: 3.5vw;
}

.Form-Header span:nth-child(2) {
  background: rgb(228, 96, 78);
  background: linear-gradient(
    470deg,
    rgba(63, 24, 204, 0.452) 0%,
    rgb(94, 42, 190) 20%,
    rgba(228, 84, 144, 0.9699813030681023) 100%
  );
  margin-right: 3.5vw;
}

.Form-Header span:nth-child(3) {
  background: rgb(228, 96, 78);
  background: linear-gradient(
    460deg,
    rgb(17, 245, 245) 0%,
    rgb(255, 91, 239) 100%,
    rgba(255, 255, 255, 0.9) 100%
  );
}
.Form-Header + p {
  color: white;
  text-align: center;
}

.Guest-Form {
  position: relative;
  width: 100vw;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  padding-bottom: 10vw;
  margin-top: 3vw;
}

.Form-Name {
  width: 35%;
  margin-bottom: 1vw;
}
.Form-Name-Input {
  width: 100%;
  padding: 1vw;
  border-radius: 0.8vw;
  border: 2.5px solid rgb(0, 37, 160);
  font-size: 1em;
  outline: rgb(255, 2, 2);
}

.Form-Vocation {
  width: 35%;
  margin-bottom: 1vw;
}

.Form-Vocation-Input {
  width: 100%;
  padding: 1vw;
  border-radius: 0.8vw;
  border: 2.5px solid rgb(199, 107, 3);
  font-size: 1em;
  outline: rgb(255, 2, 2);
}

.Form-Message {
  width: 35%;
}

.Form-Message-Textarea {
  resize: none;
  border: 4px solid rgb(90, 4, 112);
  border-radius: 1.1vw;
  padding: 1vw;
  outline: rgb(255, 2, 2);
  width: 100%;
}

.Form-File-Container {
  width: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: baseline;
          align-items: baseline;
  grid-gap: 10px;
  gap: 10px;
  font-size: clamp(1vw, 2.3vw, 3vw);
  margin: 4vw 0;
}

.Form-File-Container span {
  color: #fff;
}

.Guestbook-Label-File-Selector {
  width: auto;
}

.Guestbook-File-Selector {
  margin-top: 15px;
  padding: 1vw;
  border-radius: 1vw;
  background-color: rgb(255, 123, 0);
  color: white;
  border: 1px solid silver;
  width: 20%;
}

.Guestbook-File-Selector:hover {
  background-color: rgb(255, 102, 0);
}

/*Cross icon*/
.fa-times {
  color: rgb(255, 0, 0);
  font-size: 4.5vw;
  margin-left: 5px;
  margin-top: 1px;
}

.fa-times:hover {
  color: rgb(223, 0, 0);
}

[type="file"] {
  display: none;
}

/*Trash Can*/

.fa-trash {
  color: rgb(163, 30, 119);
  position: absolute;
  left: 25vw;
  margin-top: -4vw;
}

.fa-trash:hover:after {
  content: "Delete Guestbook";
  font-size: 1vw;
  width: 1vw;
  margin-top: 1.7vw;
  margin-left: 0.7vw;
  color: #fff;
  font-family: Montserrat;
}

.Guestbook-Theme-Container {
  margin-top: 3vw;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  grid-gap: 1.5vw;
  gap: 1.5vw;
  padding-bottom: 1vw;
}

.Guestbook-Theme-Orange {
  background: rgb(228, 96, 78);
  background: linear-gradient(
    125deg,
    rgba(228, 96, 78, 1) 0%,
    rgba(238, 99, 99, 1) 32%,
    rgba(228, 84, 144, 0.9699813030681023) 100%
  );
  height: 8vw;
  width: 8vw;
  border-radius: 360%;
  border: 1px solid white;
}

.Guestbook-Theme-Blue {
  background: rgb(228, 96, 78);
  background: linear-gradient(
    125deg,
    rgb(36, 24, 204) 0%,
    rgb(42, 82, 190) 32%,
    rgba(228, 84, 144, 0.9699813030681023) 100%
  );
  height: 8vw;
  width: 8vw;
  border-radius: 360%;
  border: 1px solid white;
}

.Guestbook-Theme-Space {
  background: rgb(228, 96, 78);
  background: linear-gradient(
    125deg,
    rgb(17, 245, 245) 0%,
    rgb(255, 91, 239) 100%,
    rgba(255, 255, 255, 0.9) 100%
  );
  height: 8vw;
  width: 8vw;
  border-radius: 360%;
  border: 1px solid white;
}

.Guestbook-Theme-Space:hover,
.Guestbook-Theme-Blue:hover,
.Guestbook-Theme-Orange:hover {
  height: 10vw;
  width: 10vw;
  margin-top: -0.5vw;
}

.Selected-Theme {
  border: 3px solid rgb(255, 2, 2);
  height: 8vw;
  width: 8vw;
  margin-top: -0.5vw;
}

.Theme-Circle-Text {
  color: rgb(255, 255, 255);
  margin: 1.7vw 0 3vw 0;
  position: relative;
}

.Guestbook-Button-Submit {
  background-color: rgb(255, 123, 0);
  border: 1px solid silver;
  margin-top: 3.5vw;
  padding: 1.1vw;
  font-size: 1.6vw;
  border-radius: 3vw;
  width: 33%;
  color: white;
}

button[disabled] {
  color: black;
  background-color: white;
  border: 2px solid black;
}

button[disabled]:hover::after {
  font-size: 1.45vw;
  position: absolute;
  margin-top: 4.5vw;
  left: 0;
  right: 0;
  color: white;
  content: "Fill in all inputs and select a theme";
}

.LikesSvgContainer {
  position: relative;
  z-index: 99;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  width: 10vw;
  position: -webkit-sticky;
  position: sticky;
  left: 87vw;
  top: 8vw;
  grid-gap: 1vw;
  gap: 1vw;
}

.SVG-Responsive-Container {
  max-width: 50%;
}

.Likes-And-SVG-Responsive-Container {
  display: -webkit-flex;
  display: flex;
  margin: 0 auto;
  position: relative;
  -webkit-align-items: center;
          align-items: center;
  width: 80%;
  margin-bottom: -85%;
}

#Likes {
  /*position: relative*/
  -webkit-flex-grow: 2;
          flex-grow: 2;
  width: auto;
  color: white;
  font-size: calc(0.5vw + 2ch);
  /*font-size: 2ch;*/
  top: -6.4vw;
  left: 5vw;
  margin-bottom: 3px;
}

#LikeButton {
  border: 3px solid #7159d2;
  background-color: transparent;
  color: white;
  font-size: 0.85vw;
  font-family: Montserrat;
  width: 10vw;
  stroke-width: 10px;
  padding: 0.81vw 1vw;
  border-radius: 22%/70%;
  background: rgb(32, 23, 108);
  background: linear-gradient(
    180deg,
    rgba(32, 23, 108, 1) 0%,
    rgba(113, 89, 210, 0) 100%
  );
}

#LikeButton:hover {
  background-color: #7159d2c7;
}

html {
  overscroll-behavior-y: none;
}

