/*Contact Form*/
.white {
  background-color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: -5px;
  height: 100%;
  width: 100%;
  z-index: 100;
  position: absolute;
  top: 0;
}

.white p:nth-child(1) {
  position: absolute;
  left: 2.5%;
  top: 22%;
  width: 21%;
  color: #fff;
  text-align: center;
  font-family: "Mr Dafoe";
  font-size: 3.5vw;
}

.Link-Home {
  background-color: "red";
  width: 10vw;
  height: 10vw;
  position: absolute;
  z-index: 102;
}

#Exit-Contact {
  color: #fff;
  z-index: 101;
  font-size: 5vw;
  position: absolute;
  z-index: 101;
  left: 94vw;
  top: 2vw;
}

#Exit-Contact:hover {
  color: red;
}

.Link-Home {
  z-index: 102;
  position: absolute;
}

.More-Content {
  margin-top: 20vw;
  display: flex;
  justify-self: center;
  align-items: center;
  flex-direction: column;
}

.white h1 {
  margin: 0px;
  text-align: center;
  font-size: 5vw;
}

.More-Content p {
  font-size: 1.5vw;
}

.Form-Container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  width: 45%;
  height: 75%;
  margin: 0 auto;
  /*border: 1px solid rgb(2, 2, 2);*/
  border-radius: 1.5vw;
  padding: 1% 10px 5%;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 101;
  /*padding: 10vw 0;*/
}

.Form-Container form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 55%;
  gap: clamp(5px, 1.5vw, 2vw);
}

.Form-Container p {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  font-size: 4vw;
  margin: 4vw 0 0.5vw 0;
  color: #fff;
}

.Form-Container label {
  width: 100%;
  padding: 0px;
}

.Form-Group {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Form-Group textarea {
  width: 90%;
  /*margin-left: -0.6vw;*/
  padding: 10px 5%;
  background-color: white;
  font-size: 1.6vw;
  color: black;
  border: none;
  outline: none;
  border-radius: 8px;
  resize: none;
  overflow: hidden;
  display: block;
  flex: 1;
}

.Form-Container input {
  width: 90%;
  display: block;
  /*margin: 0 auto;*/
  padding: 0.5em 5%;
  /*margin-left: -0.9vw;*/
  border: none;
  border-radius: 8px;
  background-color: white;
  font-size: 1.6vw;
  color: black;
  outline: none;
  flex: 1;
}

.Form-Container button:hover {
  text-decoration: underline;
}

.Form-Container button {
  width: 100%;
  /*padding: 10px 10px;*/
  border: none;
  background-color: white;
  color: black;
  border-radius: 115px;
  font-size: 2.4vw !important ;
  cursor: pointer;
}

.Form-Group textarea::placeholder,
.Form-Container button::placeholder,
.Form-Container input::placeholder,
.Form-Container button {
  font-family: "Mr Dafoe", cursive;
  font-size: 2vw;
}
